.coupon {
  // max-width: 280px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  cursor: pointer;

  @media (max-width: 500px) {
    p {
      font-size: 15px;
    }
  }

  &__image {
    margin-top: 15px;
    position: relative;

    img {
      width: 100%;
      height: 150px;
      object-fit: contain;
    }

    .image-placeholder {
      object-fit: cover;
    }
  }

  &--special {
    .coupon__image {
      img {
        height: 190px;
      }
    }

    .coupon__name {
      font-size: 18px;
    }

    .coupon__data {
      flex-basis: 58%;
    }
  }

  &__special-price {
    font-weight: 700;
    font-size: 40px;
    margin-top: 12px;
    letter-spacing: -2px !important;

    @media (max-width: 500px) {
      font-size: 36px !important;
    }

    .rtl-class & {
      display: flex;
      justify-content: flex-end;
      gap: 6px;
      flex-direction: row-reverse;
    }

    .unit {
      font-size: 20px;
      margin-top: 5px;
    }

    .special-discount {
      font-size: 15px;
      color: #8a8a8a;
      letter-spacing: 0px;
      font-weight: 400;
      margin-top: 5px;

      .ltr-class & {
        padding-left: 6px;
      }
    }
  }

  &__price {
    position: absolute;
    bottom: -5px;
    right: 10px;
    background: transparent;
    z-index: 10;
    color: #415962 !important;
    height: 75px;
    width: 75px;
    border-radius: 50%;
    // box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    // gap: 4px;
    flex-direction: column;

    .rtl-class & {
      left: 10px;
      right: unset;
      // flex-direction: row;
    }

    .value {
      font-weight: 700;
      font-size: 38px;
      letter-spacing: -2px;
    }

    .big-value {
      font-size: 32px;
    }

    .unit {
      margin-bottom: 5px;
      display: flex;
      flex-direction: column;
      text-align: center;
      font-size: 23px;
      line-height: 17px;
      // color: var(--primary);
    }

    img {
      width: 22px;
      height: 22px;
      margin-top: -10px;
      margin-left: 2px;
    }
  }

  &__price-data {
    display: flex;
    align-items: flex-end;
    gap: 4px;
    line-height: 38px;

    .ltr-class & {
      flex-direction: row-reverse;
    }

    .value {
      margin-top: -16px;
    }
  }

  &__discount {
    font-size: 13px;
    color: #8a8a8a;
  }

  &__content {
    padding: 15px;
    padding-top: 0px;
    // display: flex;
    // justify-content: space-between;
    margin-top: 10px;
    position: relative;

    @media (max-width: 500px) {
      padding-top: 0px;
    }
  }

  &__data-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    button {
      flex: 1;
    }

    @media (max-width: 500px) {
      margin-top: 15px;
    }
  }

  &__data {
    display: flex;
    flex-direction: column;

    @media (max-width: 380px) {
      flex-basis: 70%;
      width: 70%;
    }
  }

  &__name {
    color: #415962;
    font-weight: 500;
    min-height: 58px;
    line-height: 19px;

    @media (max-width: 500px) {
      min-height: 38px;
    }
  }

  &__realizations {
    color: var(--primary) !important;
    font-size: 15px;
    margin-top: 5px;
    height: 24px;
  }

  .ru-realizations {
    font-size: 13px !important;
  }

  &--special {
    .ru-realizations {
      font-size: 15px !important;
    }

    .coupon__content {
      margin-top: 22px;
    }

    .coupon__name {
      min-height: 45px;
    }
  }

  &__company {
    color: #8a8a8a !important;
  }

  &__favourite {
    position: absolute;
    top: 5px;
    left: 3px;

    .rtl-class & {
      right: 3px !important;
      left: unset !important;
    }

    > div {
      position: relative;
      border: none !important;
      inset: 0 !important;

      &::after {
        display: none !important;
      }

      img {
        height: auto !important;
      }
    }

    .quick-review {
      margin-top: 3px !important;

      .__react_component_tooltip {
        width: auto !important;
        text-align: center;
      }
    }
  }

  &:hover {
    .coupon__favourite {
      .quick-review {
        opacity: 1 !important;
      }
    }
  }

  .__react_component_tooltip {
    width: 300px !important;
    text-align: center;
  }
}

.couponInSlider {
  @media (max-width: 500px) {
    .coupon__name {
      min-height: 56px !important;
    }
  }
}

#tooltip-container .__react_component_tooltip p {
  color: white !important;
}

#tooltip-container .__react_component_tooltip {
  width: 250px !important;

  .rtl-class & {
    text-align: right !important;
  }
}
